@import '~node_modules/@pacificspecialtyinsurancecompany/psic-ng-components/assets/styles/styles.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~node_modules/@pacificspecialtyinsurancecompany/psic-ng-components/assets/styles/variables';

.modal {
    width: 100%;

    .modal-dialog {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .modal-content {
            height: auto;
            border: none;
            border-radius: 0;

            .content-align {
                display: grid;
                justify-items: center;
            }

            .modal-footer {
                background: $p3-40;
                padding: 0 24px;
                position: relative;
                width: 100%;
                height: 66px;
                left: 0;
                bottom: 0;

                .goback-text {
                    padding-top: 2px;
                    padding-left: 0;
                    cursor: pointer;
                }

                .back-img {
                    padding-right: 0;
                    height: 20px;
                    width: 20px;
                    background: URL('../../../../assets/images/back-arrow-wh.svg') no-repeat;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .modal {
        padding-top: 0;
        width: 100%;

        .modal-dialog {
            max-width: 588px;
            margin: 1.5rem auto;
            display: flex;
            align-items: center;
            justify-content: center;

            .modal-content {
                height: auto;
                width: auto;
                max-width: 792px;
                padding: 32px 16px;
                box-shadow: 0 5.05408px 10.0417px rgba(39, 69, 85, 0.0376886),
                    0 2.10311px 4.17856px rgba(39, 69, 85, 0.0270181);
                border-radius: 30px;

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .goback-text {
                    padding-top: 2px;
                    padding-left: 0;
                    cursor: pointer;
                }

                .back-img {
                    padding-right: 0;
                    height: 20px;
                    width: 20px;
                    background: URL('../../../../assets/images/back-arrow.svg') no-repeat;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .desktop {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .mobile {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    .mobile-edit-quote {
        display: none !important;
    }
}

@media screen and (max-width: 1281px) {
    .desktop-edit-quote {
        display: none !important;
    }
}

.responsive-horizontal-form-container {
    @media (max-width: 767px) {
        width: 325px !important;
    }

    @media (min-width: 768px) {
        width: 550px !important;
    }
}

mat-form-field {
    .mat-form-field {
        &-underline {
            position: static;
        }

        &-wrapper {
            padding-bottom: 0;
        }

        &-subscript-wrapper {
            position: static;
            min-height: 1rem;
        }
    }
}
.responsive-vertical-form-container {
    @media (max-width: 767px) {
        width: 325px !important;
    }

    @media (min-width: 768px) {
        width: 384px !important;
    }
}

// this class should be deprecated - its not fair to set all form fields here. Instead we should
// have fields that size to they're container and set the size of the container.
.mat-form-field {
    @media (max-width: 767px) {
        width: 325px !important;
    }

    @media (min-width: 768px) {
        width: 384px !important;
    }

    @media (min-width: 768px) {
        .mobile {
            display: none !important;
        }
    }
}
